<template>
<nav class="d-flex flex-lg-column navbar navbar-expand-lg float-lg-none sticky-top">
  <div class="d-lg-none d-block mr-auto">
    <router-link to="/" class="nav-link">
      <img src="@/assets/img/home/logo.svg" style="width:120px; height:auto"/>
    </router-link>
  </div>
  
  <div class="d-md-none d-block">
    <a href="https://twitter.com/Kigyousetuneik1">
      <img src="@/assets/img/home/Twitter.svg" class="sns-logo mx-2"/>
    </a>
    <a href="https://lin.ee/jNda0ZD">
      <img src="@/assets/img/home/LINE.svg" class="sns-logo mx-2"/>
    </a>
    <a href="https://www.instagram.com/akita_kigyousetumeika/">
      <img src="@/assets/img/home/Instagram.svg" class="sns-logo mx-2"/>
    </a>
  </div>
  <button type="button" class="navbar-toggler" data-bs-toggle="offcanvas" data-bs-target="#Navber" aria-controls="Navber" aria-expanded="false" aria-label="ナビゲーションの切替">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="offcanvas offcanvas-end" id="Navber">
    <div class="py-lg-5 sidebar">  <!--ここに高さを指定することでサイドバーの高さを調整できて、ナビがスクロールで付いてくる-->
      <div class="sidebar_fixed">
        <button type="button" class="btn-close d-lg-none text-reset float-end border border-dark" data-bs-dismiss="offcanvas" aria-label="Close" style="width:40px; height:40px;"></button>
        <router-link to="/" class="nav-link">
          <img src="@/assets/img/home/logo.svg" class="d-block mx-auto" style="width:150px; height:auto;"  data-bs-dismiss="offcanvas" aria-label="Close"/>
        </router-link>
        <hr>
        <ul class="nav nav-pills flex-column mb-auto">
          <li class="nav-item">
            <router-link to="/company" class="nav-link">
              <button class="link border border-dark border-3 d-block rounded mx-auto" data-bs-dismiss="offcanvas" aria-label="Close" style="min-width:150px">
                <div style="font-size:small;" class="p-3 fw-bold">企業一覧</div>
              </button>
            </router-link>
          </li>
          <li>
            <router-link to="/event" class="nav-link">
              <button class="link border border-dark border-3 d-block rounded mx-auto" data-bs-dismiss="offcanvas" aria-label="Close" style="min-width:150px">
                <div style="font-size:small;" class="p-3 fw-bold">イベント詳細</div>
              </button>
            </router-link>
          </li>
          <li>
            <router-link to="/hoken" class="nav-link">
              <button class="link border border-dark border-3 d-block rounded mx-auto" data-bs-dismiss="offcanvas" aria-label="Close" style="min-width:150px">
                <div style="font-size:small;" class="p-3 fw-bold">就活の保健室</div>
              </button>
            </router-link>
          </li>
        </ul>
        <hr>
        <div class="row justify-content-center">
          <div class="col-8">
              <img src="@/assets/img/home/suwaru.svg" class="d-block mx-auto my-1"/>
          </div>
        </div>
        <div class="row justify-content-center my-1">
          <div class="col-2 col-lg-4">
            <a href="https://twitter.com/Kigyousetuneik1">
              <img src="@/assets/img/home/Twitter.svg" class="w-100"/>
            </a>
          </div>
          <div class="col-2 col-lg-4">
            <a href="https://lin.ee/jNda0ZD">
              <img src="@/assets/img/home/LINE.svg" class="w-100"/>
            </a>
          </div>
          <div class="col-2 col-lg-4">
            <a href="https://www.instagram.com/akita_kigyousetumeika/">
              <img src="@/assets/img/home/Instagram.svg" class="w-100"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div><!-- /.navbar-collapse -->
</nav>
</template>
<script>
</script>
<style scoped>
.sns-logo {
  width: 25px;
  max-width: 30px;
  height: 25px;
}

.sidebar_fixed {
  position: sticky;
  top: 5px;
}
.sidebar_content {
  margin-bottom: 100px;
}

.offcanvas {
  background-color: #f4fafa;
}

.link {
  background-color: white;
}

.link:hover {
  background-color:aliceblue;
  text-decoration: none;
}

</style>