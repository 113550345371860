<template>
<div class="row d-none d-lg-flex" style="margin-top:100px;">
  <div class="position-relative" style="z-index:100">
    <div class="col-7 position-absolute top-0 start-0 translate-middle-y">
      <img src="@/assets/img/home/logo.svg" width="auto" class="w-100"/>
    </div>
  </div>
</div>

<div class="row d-flex d-lg-none justify-content-center my-4">
  <div class="col-7">
    <img src="@/assets/img/home/logo.svg" width="auto" class="w-100"/>
  </div>
</div>

<div class="row d-flex">
  <div class="position-relative" style="z-index:0">
  <div class="row justify-content-center">
    <div id="carouselExampleIndicators" class="carousel carousel-dark slide col-10" data-bs-ride="carousel">
      <!-- インジケータの設定 -->
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="スライド 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="スライド 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="スライド 3"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="スライド 4"></button>
      </div>
      <!-- スライドさせる画像の設定 -->
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="@/assets/img/slideshow/slide1.webp" class="mx-auto"/>
        </div><!-- /.carousel-item -->
        <div class="carousel-item">
          <img src="@/assets/img/slideshow/slide2.webp"/>
        </div><!-- /.carousel-item -->
        <div class="carousel-item">
          <img src="@/assets/img/slideshow/slide3.webp"/>
        </div><!-- /.carousel-item -->
        <div class="carousel-item">
          <img src="@/assets/img/slideshow/slide4.webp"/>
        </div><!-- /.carousel-item -->
      </div><!-- /.carousel-inner -->
      <!-- スライドコントロールの設定 -->
      <button type="button" class="carousel-control-prev fs-1" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <i class="bi bi-arrow-left-square-fill"></i>
      </button>
      <button type="button" class="carousel-control-next fs-1" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <i class="bi bi-arrow-right-square-fill"></i>
      </button>
    </div><!-- /.carousel -->
  </div>
  </div>
</div>

<div class="row col-11">
  <div class="position-relative" style="z-index:100">
    <div class="col-4 position-absolute top-0 end-0 translate-middle-y">
      <img src="@/assets/img/home/akita.svg" width="auto" class="w-100"/>
    </div>
  </div>
</div>

<div class="d-none d-lg-flex">
  <div style="height:200px"></div>
</div> 
<div class="d-flex d-lg-none">
  <div style="height:120px"></div>
</div> 

<div class="row justify-content-center">
  <div class="col-11">
    <div class="container">
      <div class="row d-lg-flex align-items-start justify-content-center text-start">
        <div class="col-11 col-xl-8">
            <p class="fw-bold fs-5">みなさんは、企業に何を求めますか？</p>
            <p class="fw-bold fs-5">就職後の自分がその企業で働いているイメージはできますか？</p>
            <p>Bucchake!?は、秋田の企業のぶっちゃけた裏側の話をテーマに、就活生への企業選びのサポートになる情報・コンテンツをまとめています。</p>
        </div>
        <div class="col-7 col-xl-4">
          <img src="@/assets/img/home/nayami.svg" width="auto"/>
        </div>
      </div>
    </div>

    <div style="height:80px"></div> 

    <div class="container">
      <div class="row d-flex justify-content-center my-5">
        <div class="company border border-dark border-3 rounded">
          <div class="row" style="height:90px">
            <div class="position-relative">
              <div class="position-absolute top-0 start-50 translate-middle">
                <div class="col-11 mx-auto">
                  <img src="@/assets/img/home/kenkyuu_logo.svg" width="auto" class="w-100"/>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-5">
            <div class="text-center fw-bold fs-5 p-2">
              <span style="background-image: linear-gradient(transparent 50%, lightblue 0%)">
                合同就活イベントに参加する
              </span>
            </div>
            <div class="text-center fw-bold fs-5 p-2">
              <span style="background-image: linear-gradient(transparent 50%, lightblue 0%)">
                市内企業32社を研究できるスペース
              </span>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-11 d-lg-flex">
              <div class="col-12 col-lg-7 text-start p-4">
                <p>秋田市には、どんな企業があり、どんな人が何を考えて働いているのでしょうか？</p>
                <br/>
                <p>秋田の企業にインタビューを行い、実際に働く社員のリアルな声をまとめました。</p>
                <p>まずは、興味のわく企業を探しましょう！</p>
              </div>
              <div class="col-7 col-lg-5 mx-auto">
                <img src="@/assets/img/home/shiraberu.svg" width="auto" class="w-100"/>
              </div>
            </div>
          </div>

          <div v-show="loading" class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>

          <div v-show="!loading">
            <div class="row justify-content-center mt-4">
              <div class="col-12 col-lg-10">
                <div id="carouselCompanyControls" class="carousel slide carousel-dark" data-bs-ride="carousel">
                  <!-- スライドさせる画像の設定 -->
                  <div class="carousel-inner">
                    <div class="col-9 col-lg-9 mx-auto" v-for="(item, index) in ary" :key="item">
                      <div :class="['carousel-item', {'active':index==0}]">
                        <router-link :to="{ path: `/company/${item.id}`}">
                          <div class="flex-column border border-dark rounded p-0" style="background-color:#f2f2f2;">
                            <div class="p-3 text-center">
                              <img :src="item.thumbnail.url" class="img-thumbnail w-100 slide-img"/>
                            </div>
                            <div class="text-center fs-6">
                              <strong>{{item.name}}</strong><br>
                            </div>
                            <div class="text-center fs-6">
                              {{item.title}}<br>
                            </div>
                          </div>
                        </router-link>
                      </div><!-- /.carousel-item -->
                    </div>
                      
                  </div><!-- /.carousel-inner -->
                  <!-- スライドコントロールの設定 -->
                  <button type="button" class="carousel-control-prev" data-bs-target="#carouselCompanyControls" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">前へ</span>
                  </button>
                  <button type="button" class="carousel-control-next" data-bs-target="#carouselCompanyControls" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">次へ</span>
                  </button>
                </div><!-- /.carousel -->
              </div>
            </div>
          </div>


          <div class="row flex-column text-center align-items-center my-4 mb-lg-4">
            <div class="col-8">
              <div class="col-5 col-lg-6 d-block mx-auto my-4">
                <router-link to="/company"><img src="@/assets/img/home/ear.svg" style="width: auto;"/></router-link>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>

    <div style="height:80px"></div> 

    <div class="container">
      <div class="row d-flex justify-content-center my-5">
        <div class="company border border-dark border-3 rounded">
          <div class="row" style="height:90px">
            <div class="position-relative">
              <div class="position-absolute top-0 start-50 translate-middle">
                <div class="col-11 mx-auto">
                  <img src="@/assets/img/home/event_logo.svg" width="auto" class="w-100"/>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-5">
            <div class="text-center fw-bold fs-5 p-2">
              <span style="background-image: linear-gradient(transparent 50%, lightblue 0%)">
                秋田市の多くの企業と関わることのできる
              </span>
            </div>
            <div class="text-center fw-bold fs-5 p-2">
              <span style="background-image: linear-gradient(transparent 50%, lightblue 0%)">
                合同就活イベントの紹介
              </span>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-lg-11 d-lg-flex">
              <div class="col-12 col-lg-7 justify-content-center text-start p-4">
                <p>従来の説明会ではなく、学生と企業が対話しやすい環境を作りました！</p>
                <br/>
                <p>企業と話せるとても良い機会なので、ぜひご参加ください！！</p>
              </div>
              <div class="col-7 col-lg-5 mx-auto">
                <img src="@/assets/img/home/ojisan.svg" width="auto" class="w-100"/>
              </div>
            </div>
          </div>
          <div class="row flex-column text-center align-items-center my-5 mb-lg-4">
            <div class="col-8">
              <div class="col-5 col-lg-6 mx-auto my-4">
                <router-link to="/event"><img src="@/assets/img/home/miru.svg" style="width: auto;"/></router-link>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>

    <div style="height:90px"></div> 

    <div class="container">
      <div class="row d-flex justify-content-center my-5">
        <div class="company border border-dark border-3 rounded">
          <div class="row" style="height:90px">
            <div class="position-relative">
              <div class="position-absolute top-0 start-50 translate-middle">
                <div class="col-11 mx-auto">
                  <img src="@/assets/img/home/hokennshitsu_logo.svg" width="auto" class="w-100"/>
                </div>
              </div>
            </div>
          </div>

          <div class="row my-5">
            <div class="text-center fw-bold fs-5 p-2">
              <span style="background-image: linear-gradient(transparent 50%, lightblue 0%)">
                就活で生まれる悩みが集まる
              </span>
            </div>
            <div class="text-center fw-bold fs-5 p-2">
              <span style="background-image: linear-gradient(transparent 50%, lightblue 0%)">
                就活の保健室
              </span>
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col-12 col-lg-11 d-lg-flex">
              <div class="col-lg-7 justify-content-center text-start p-4">
                <p>就活で感じる悩み、友人には相談できない悩みなど、お聞かせいただけませんか？</p>
                <br/>
                <p>キャリアコンサルタントの資格を持つ就活カウンセラーが、カウンセリングを通して就活をお手伝いさせていただきます。</p>
                <br/>
                <p>ひとことから、気軽にご相談ください。</p>
              </div>
              <div class="col-4 col-lg-4 mx-auto">
                <img src="@/assets/img/home/counselor.svg" width="auto" class="w-100"/>
              </div>
            </div>
          </div>
          <div class="row flex-column text-center align-items-center my-5 mb-lg-4">
            <div class="col-8">
              <div class="col-5 col-lg-6 d-block mx-auto my-4">
                <router-link to="/hoken"><img src="@/assets/img/home/door.svg" style="width: auto;"/></router-link>
              </div>
            </div>
          </div>
        </div> 
      </div>
    </div>
  </div>
</div>
  
</template>

<style scoped>
a{
  color: black;
  text-decoration: none;
}

.slide-img {
  display: block;
  width: 100%;/*任意の横幅を指定*/
  height: 200px;/*任意の高さを指定*/
  object-fit: contain;
  background-color: #f2f2f2;;
}
</style>
<script>
export default {
  name: 'HomeView',
  data(){
    return{
      ary : [],
      loading: true,
    }
  },
  mounted : async function(){
    await this.$client.get({
      endpoint: 'company',
      queries: { limit: 100 },
    })
    .then((res) => {
      this.ary = this.arrayShuffle(res.contents);

      this.loading = false;
    })
    .catch((err) => console.log(err));
  },
  methods : {
    arrayShuffle(array) {
      for(var i = (array.length - 1); 0 < i; i--){

        // 0〜(i+1)の範囲で値を取得
        var r = Math.floor(Math.random() * (i + 1));

        // 要素の並び替えを実行
        var tmp = array[i];
        array[i] = array[r];
        array[r] = tmp;
      }
      return array;
    }
  }
}
</script>
