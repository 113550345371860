<template>
<div class="d-flex align-items-center justify-content-center mt-4">
  <div class="col-6 col-lg-4">
    <img src="@/assets/img/hoken/hokennshitsu_logo.svg"/>
  </div>
</div>

<div class="text-center fw-bold fs-5 mt-3">
  <span style="background-image: linear-gradient(transparent 50%, lightblue 0%)">
    匿名相談可能・会話感覚のオンライン相談
  </span>
</div>

<div class="row justify-content-center my-5">
  <div class="col-10 col-lg-6 my-auto">
    <img src="@/assets/img/hoken/setsumei.png" width="auto" class="w-100"/>
  </div>

  <div class="col-10 col-lg-6">
    <div class="row text-start my-5">
      <p>就活は長期戦になりがちです。</p>
      <p>面接で話せなかったり、周囲と比較して結果が思うようにいかないことがあると思います。</p>
      <p>匿名でも相談が可能なので、気軽にご相談ください。</p>
    </div>
  </div>
</div>


<!--匿名・会話感覚の相談-->

<div class="container">
  <div class="row d-flex justify-content-center my-5">
    <div class="company col-11 col-md-12">
      <div class="row">
        <div class="col-md-8 justify-content-center text-start p-4 border border-dark rounded border-4">
          <p>就職活動を始めるといろいろな<span style="color:#E68B89;">「思い」</span>がありますよね。</p>
          <p>友達、家族にもなかなか言えない心の中にある<span style="color:#E68B89;">「思い」</span>を就活の保健室で話してみませんか？</p>
          <p><span style="color:#E68B89;">不安、愚痴、自慢話</span>。なんでも話して大丈夫ですよ。皆さんのお話を聞かせていただけるのを待っています。</p>
        </div>
        <div class="col-md-4 d-flex flex-column align-items-center justify-content-end mt-3 mt-lg-0">
          <img src="@/assets/img/home/counselor.svg" width="auto" class="w-75"/>
          <p>就活カウンセラー 伊藤さん</p>
        </div>
      </div>
    </div> 
    <div class="d-grid gap-2 col-6 mx-auto">
      <button type="button" class="btn btn-light btn-lg p-4 border border-dark mt-4" style="background-color:#f2f2f2" onclick="location.href='https://docs.google.com/forms/d/1PGrxsteRsHpgZ8FdCJi4yOpSLdpz9-rGWBNj_qFAp4k/edit'">相談する</button>
    </div>
  </div>
</div>  
</template>

<style>

</style>
