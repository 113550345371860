<template>
    <div class="row">
      <div class="col-lg-3 order-1 order-lg-2 sidebar">
        <SideBar/>
      </div>
      <div class="col-lg-9 order-1 order-lg-1 content">
        <hr class="d-block d-lg-none"/>
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10 p-0">
            <router-view/>
          </div>
        </div>
        <FooterLine/>
      </div>
    </div>
</template>

<script>
import SideBar from '@/components/SideBar.vue'
import FooterLine from '@/components/FooterLine.vue'

export default {
  name: 'HokenView',
  components: {
    SideBar,
    FooterLine
},
  data(){
    return{

    }
  },
}
</script>

<style>
.border{
  border-color: #f4fafa;
}

body *{
  font-family: 'Noto Sans JP', sans-serif;
  color: #1D4245;
}

.sidebar {
  background-color: #B9D7DD;
}

.content {
  background-color: #f4fafa;
}

.company {
  height: auto;
}

@media (max-width:992px) {
  .sidebar {
  background-color: #f4fafa;
}
}
</style>


