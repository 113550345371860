<template>
  <div class="row mt-5 my-lg-5">
    <div class="col-10 col-lg-9">
      <img src="@/assets/img/event/headline_1.svg"/>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="row my-5">
      <div class="text-center fw-bold fs-5 p-2">
        <span style="background-image: linear-gradient(transparent 50%, lightblue 0%)">
          市内企業が集結！
        </span>
      </div>
      <div class="text-center fw-bold fs-5 p-2">
        <span style="background-image: linear-gradient(transparent 50%, lightblue 0%)">
          ８つのテーマをもとに進む就活イベント
        </span>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-10 col-lg-7 text-start">
      <p>秋田市内32社の参加企業を、就活生が気になる8テーマで分けました。</p>
      <br/>
      <p>自分の気になるテーマを選び参加することで、</p>
      <p>秋田の企業の取り組みや働いている人の話を一度に聞くことができます。</p>
    </div>
  </div>

  <div class="row justify-content-center px-4">
    <div class="col-12 col-lg-10 d-flex text-center">
      <div class="col-4 mt-1 mt-md-2">
        <img src="@/assets/img/event/setsumei_left.svg"/>
      </div>
      <div class="col-4">
        <img src="@/assets/img/event/setsumei_center.svg"/>
      </div>
      <div class="col-4">
        <img src="@/assets/img/event/setdumei_rihgt.svg"/>
      </div>
    </div>
  </div>

  <div class="row justify-content-center my-5">
    <div class="col-8 col-lg-4 text-center">
      <img src="@/assets/img/event/theme.svg"/>
    </div>
  </div>

  <div class="row justify-content-center my-3">
    <div class="col-11 col-lg-6 text-center">
      <img src="@/assets/img/event/headline/worklife.svg"/>
    </div>
    <div class="col-10 col-lg-9 text-start my-3">
      <div class="fs-6">
        「秋田でずっと暮らしたい」「経験を積むために一度は県外に出たいけど、いつかは秋田に戻って働きたい」
        「フレックスな働き方をしたい」など、働く場所や働き方は就職先を選ぶ上で重要ですよね。<br/>
        自分らしい働き方や働く場所へのこだわりを大切にしてくれる市内企業と出会いたい方向けのセッションです。 
      </div>

      <div class="row d-flex my-4">
        <div class="col-2 col-lg-1 p-0 text-center">
          <img src="@/assets/img/event/number/1.svg"/>
        </div>
        <div class="col-10 col-lg-7">
          <div class="fs-5">秋田県内でずっと働ける</div>
          <div class="fs-6">家族や友達といつでも会える距離で暮らし、マイホームなどの人生設計もしやすい地元思考な働き方</div>
        </div>
        <div class="col-lg-4 text-center my-3">
          <img src="@/assets/img/event/tag/worklife_tag_1.svg"/>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 text-center">
          <img src="@/assets/img/event/theme01.svg"/>
        </div> 
      </div>

      <div class="row d-flex my-4">
        <div class="col-2 col-lg-1 p-0 text-center">
          <img src="@/assets/img/event/number/2.svg"/>
        </div>
        <div class="col-10 col-lg-7">
          <div class="fs-5">多様な働き方を選べる</div>
          <div class="fs-6">テレワーク、フレックスタイム制、副業兼業、時短勤務など、自分の可能性を広げる多様な働き方</div>
        </div>
        <div class="col-lg-4 text-center my-3">
          <img src="@/assets/img/event/tag/worklife_tag_1.svg"/>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 text-center">
          <img src="@/assets/img/event/theme02.svg"/>
        </div> 
      </div>

      <hr/>
    </div>
  </div>

  <div class="row justify-content-center my-3">
    <div class="col-11 col-lg-6 text-center">
      <img src="@/assets/img/event/headline/environment.svg"/>
    </div>
    <div class="col-10 col-lg-9 text-start my-3">
      <div class="fs-6">
        「人間関係が良好で居心地がいい職場で働きたい」など、長く働く場所だからこそ、こだわりたいですよね。
        あなたに合った職場環境を提供してくれる市内企業と出会いたい方向けのセッションです。 
      </div>

      <div class="row my-4 justify-content-center">
        <div class="col-12 col-lg-10 text-center">
          <img src="@/assets/img/event/theme03.svg"/>
        </div> 
      </div>

      <div class="row d-flex my-4">
        <div class="col-2 col-lg-1 p-0 text-center">
          <img src="@/assets/img/event/number/4.svg"/>
        </div>
        <div class="col-10 col-lg-7">
          <div class="fs-5">心理的安全性が高い</div>
          <div class="fs-6">心理的安全性とは、組織の中で自分の考えや気持ちを誰に対してでも安心して発言できる状態のこと</div>
        </div>
        <div class="col-lg-4 text-center my-3">
          <img src="@/assets/img/event/tag/enveronment_tag_2.svg"/>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 text-center">
          <img src="@/assets/img/event/theme04.svg"/>
        </div> 
      </div>

      <hr/>
    </div>
  </div>

  <div class="row justify-content-center my-3">
    <div class="col-11 col-lg-6 text-center">
      <img src="@/assets/img/event/headline/social.svg"/>
    </div>
    <div class="col-10 col-lg-9 text-start my-3">
      <div class="fs-6">
        「SDGsに貢献したい」「地域に貢献したい」など、仕事を通じて社会貢献したい就活生や、企業の社会的役割を重視している就活生は近年増加傾向にあります。<br/>
        本業や課外活動を通じて積極的に社会貢献に取り組む市内企業と出会いたい方向けのセッションです。
      </div>

      <div class="row d-flex my-4">
        <div class="col-2 col-lg-1 p-0 text-center">
          <img src="@/assets/img/event/number/5.svg"/>
        </div>
        <div class="col-10 col-lg-7">
          <div class="fs-5">SDGsを推進</div>
          <div class="fs-6">企業のSDGsの取り組みが就職先を選定する際の判断基準になったと約??%以上の就活生が回答</div>
        </div>
        <div class="col-lg-4 text-center my-3">
          <img src="@/assets/img/event/tag/social_tag_1.svg"/>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 text-center">
          <img src="@/assets/img/event/theme05.svg"/>
        </div> 
      </div>

      <div class="row d-flex my-4">
        <div class="col-2 col-lg-1 p-0 text-center">
          <img src="@/assets/img/event/number/6.svg"/>
        </div>
        <div class="col-10 col-lg-7">
          <div class="fs-5">地域貢献・地域創生を推進</div>
          <div class="fs-6">地域に根ざし、地域活動への積極的な参加を通じて地域の未来を作る地元密着型企業</div>
        </div>
        <div class="col-lg-4 text-center my-3">
          <img src="@/assets/img/event/tag/social_tag_2.svg"/>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 text-center">
          <img src="@/assets/img/event/theme06.svg"/>
        </div> 
      </div>

      <hr/>
    </div>
  </div>

  <div class="row justify-content-center my-3">
    <div class="col-11 col-lg-6 text-center">
      <img src="@/assets/img/event/headline/benefit.svg"/>
    </div>
    <div class="col-10 col-lg-9 text-start my-3">
      <div class="fs-6">
        「給与・待遇の良い企業で働きたい」「福利厚生制度が充実した企業で働きたい」「残業・休日出勤が少ない企業で働きたい」など、
        福利厚生に関する就活軸は常にランキング上位に入っています。<br/>
        社員の幸せのために手厚い福利厚生制度を設けている市内企業と出会いたい方向けのセッションです。
        
      </div>

      <div class="row d-flex my-4">
        <div class="col-2 col-lg-1 p-0 text-center">
          <img src="@/assets/img/event/number/7.svg"/>
        </div>
        <div class="col-10 col-lg-7">
          <div class="fs-5">法定外福利厚生が充実</div>
          <div class="fs-6">法定外福利厚生とは、社員の心身と経済的な幸福のために企業が独自に採用するもの</div>
        </div>
        <div class="col-lg-4 text-center my-3">
          <img src="@/assets/img/event/tag/benefit_tag_1.svg"/>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 text-center">
          <img src="@/assets/img/event/theme07.svg"/>
        </div> 
      </div>

      <div class="row d-flex my-4">
        <div class="col-2 col-lg-1 p-0 text-center">
          <img src="@/assets/img/event/number/8.svg"/>
        </div>
        <div class="col-10 col-lg-7">
          <div class="fs-5">ワークライフバランスを大切に</div>
          <div class="fs-6">ワークライフバランスとは、仕事とプライベートが”共に”充実できるバランスが整っている状態のこと</div>
        </div>
        <div class="col-lg-4 text-center my-3">
          <img src="@/assets/img/event/tag/benefit_tag_2.svg"/>
        </div>
      </div>

      <div class="row justify-content-center">
        <div class="col-12 col-lg-10 text-center">
          <img src="@/assets/img/event/theme08.svg"/>
        </div> 
      </div>
    </div>
  </div>

  <div class="row justify-content-center my-5">
    <div class="col-8 col-lg-4 text-center">
      <img src="@/assets/img/event/schedule.svg"/>
    </div>
  </div>

  <div class="row justify-content-center my-5">
    <div class="col-11 text-center">
      <img src="@/assets/img/event/timetable.png"/>
    </div>
  </div>

  <div class="row justify-content-center my-5">
    <div class="col-8 col-lg-4 text-center">
      <img src="@/assets/img/event/about.svg"/>
    </div>
  </div>

  <div class="row justify-content-center d-flex my-5">
    <div class="col-11 col-lg-7 flex-columns text-start fs-6 fw-bold">
      <div class="row d-flex">
        <div class="col-3 text-end">[名称]</div>
        <div class="col-9">秋田市主催”テーマ別”企業研究会</div>
      </div>
      <div class="row d-flex my-1">
        <div class="col-3 text-end">[日時]</div>
        <div class="col-9 flex-columns">
          <div>2022 年 9 月 20 日( 火 ) ・ 22 日(木)</div>
          <div>【受付】 9:40  ①10:00-12:20</div>
          <div>【受付】 13:10  ①13:30-15:50</div>
        </div>
      </div>
      <div class="row d-flex my-1">
        <div class="col-3 text-end">[場所]</div>
        <div class="col-9">ALVE(アルヴェ)2F多目的ホール</div>
      </div>
      <div class="row d-flex my-1">
        <div class="col-3 text-end">[対象]</div>
        <div class="col-9">大学3年生(1・2年生も可)、短大、高専、専門学生</div>
      </div>
      <div class="row d-flex my-1">
        <div class="col-3 text-end">[服装]</div>
        <div class="col-9">自由(カジュアル推奨)</div>
      </div>

      
    </div>
    <div class="col-11 col-lg-4 col-xl-5 text-center my-4 my-lg-0">
      <img src="@/assets/img/event/map.svg"/>
    </div>
  </div>

  <div class="row d-flex justify-content-center">
    <div class="d-grid gap-2 col-10 col-lg-5 mx-auto">
      <router-link to="/company">
        <button type="button" class="btn btn-light btn-lg p-4 border border-dark mt-4 fs-6 w-100" style="background-color:#f2f2f2">参加企業一覧を見る</button>
      </router-link>
    </div>

    <div class="d-grid gap-2 col-10 col-lg-5 mx-auto" >
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSd_ssSCUNHts6Ln90GhLSCSnCiW9Of6FfFjXoBQgBBCS_UtGA/viewform?vc=0&c=0&w=1&flr=0">
      <button type="button" class="btn btn-light btn-lg p-4 border border-dark mt-4 fs-6 w-100" style="background-color:#f2f2f2">イベントに参加する</button>
      </a>
    </div>
  </div>

  <div class="row mt-5 my-lg-5">
    <div class="col-10 col-lg-9">
      <img src="@/assets/img/event/headline_2.svg"/>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="row my-5">
      <div class="text-center fw-bold fs-5 p-2">
        <span style="background-image: linear-gradient(transparent 50%, #f1b1b0 0%)">
          テーマ別企業研究会の事後イベント
        </span>
      </div>
      <div class="text-center fw-bold fs-5 p-2">
        <span style="background-image: linear-gradient(transparent 50%, #f1b1b0 0%)">
          より深く企業のことが知れる。会話形式の座談会
        </span>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-4 text-center">
      <img src="@/assets/img/event/person_1.svg"/>
    </div>
    <div class="col-4 text-center">
      <img src="@/assets/img/event/person_2.svg"/>
    </div>
  </div>

  <div class="row justify-content-center py-4">
    <div class="col-10 col-lg-8 text-start">
      <p>「テーマ別企業研究会」で出会った企業と次の接点を作ることができます。</p>
      <p>イベント参加後のアンケートで、申込が可能です。</p>
      <p>次のステップに進みための大きなきっかけになるので、ぜひ応募してみてください。</p>
    </div>
  </div>

  <div class="row justify-content-center mb-5 px-4">
    <div class="col-12 col-lg-10 d-flex text-center">
      <div class="col-4">
        <img src="@/assets/img/event/zadankai_left.svg"/>
      </div>
      <div class="col-4">
        <img src="@/assets/img/event/zadankai_center.svg"/>
      </div>
      <div class="col-4">
        <img src="@/assets/img/event/zadankai_right.svg"/>
      </div>
    </div>
  </div>



</template>
<style>
hr {
   border-width: 4px 0px 0px 0px; /* 太さ1px */
   border-style: dotted; /* 線種を破線に */
   border-color: #1D4245;   /* 線色を灰色に */
   height: 1px;         /* 高さ(※古いIE用) */
}
</style>
<script>
</script>