<template>
<div class="container">
  <div class="row justify-content-center">
    <div class="col-11 col-lg-10">
      <div v-show=loading style="height:600px;" class="row text-center justify-content-center">
        <div  class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div v-show="!loading && error">
        <div style="height: 400px;" class="row flex-column justify-content-center">
          <div class="text-center">
            <h2>このページは存在しません。</h2>
          </div>
        </div>
      </div>

      <div v-show="!loading && !error">
        <div style="min-height:100px"></div>
        <div class="row informationContent" v-html="contents.content"></div>

        <div class="row d-flex justify-content-center">
          <div class="d-grid gap-2 col-10 col-lg-6 mx-auto">
            <router-link to="/event">
              <button type="button" class="btn btn-light btn-lg p-4 border border-dark mt-4 w-100" style="background-color:#f2f2f2">イベントに参加する</button>
            </router-link>
          </div>

          <div v-show="contents.link != null">
            <div class="d-grid gap-2 col-10 col-lg-6 mx-auto" >
              <button type="button" class="btn btn-light btn-lg p-4 border border-dark mt-4 w-100" style="background-color:#f2f2f2" v-on:click="showlink">前回の紹介ページ</button>
            </div>
          </div>

          <div style="min-height:100px"></div>

          <div class="row justify-content-center">
            <div class="col col-lg-11 justify-content-center">
              <div class="border border-dark border-3 rounded pb-3">
                <div class="text-center h5 p-4">詳細情報</div>

                <div class="row justify-content-center">
                  <div class="col-10 col-lg-11">
                    <div class="row justify-content-center d-flex">
                      <div class="col-3 justify-content-center border-end border-dark border-2">
                        <div class="text-start text-lg-center">企業名</div>
                      </div>
                      <div class="col-9">
                        <div class="pb-3">{{contents.name}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-10 col-lg-11">
                    <div class="row justify-content-center d-flex">
                      <div class="col-3 justify-content-center border-end border-dark border-2">
                        <div class="text-start text-lg-center">HP</div>
                      </div>
                      <div class="col-9">
                        <div class="pb-3">
                          <a @click="externalLink">
                            <span style="text-decoration: underline; color:blue">{{contents.homepage}}</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-10 col-lg-11">
                    <div class="row justify-content-center d-flex">
                      <div class="col-3 justify-content-center border-end border-dark border-2">
                        <div class="text-start text-lg-center">住所</div>
                      </div>
                      <div class="col-9">
                        <div class="pb-3">{{contents.address}}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-10 col-lg-11">
                    <div class="row justify-content-center d-flex">
                      <div class="col-3 justify-content-center border-end border-dark border-2">
                        <div class="text-start text-lg-center">業種</div>
                      </div>
                      <div class="col-9">
                        <div class="pb-3">{{contents.work}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="min-height:100px"></div>
        </div>
      </div>
    </div>
  </div>
</div> 
</template>

<style>
img {
  max-width:100%;
}
</style>
<script>
export default {
  name: 'CompanyDetailDevelopmentView',
  data () {
    return {
      contents : [],
      loading: true,
      error: false,
      id: this.$route.path.params,
      key:this.$route.query.draftKey,
    }
  },
  mounted : async function(){
    await this.$client.get({
      
      endpoint: 'company',
      contentId: this.$route.params.id,
      queries: { draftKey: this.$route.query.draftKey },
      
    })
    .then((res) => {

      console.log(this.$route.params.id)
      console.log(this.$route.query.draftKey)
      console.log(res)

      this.contents = res
      console.log(this.contents)
      this.contents.content = this.contents.content.replace(/&lt;/g,'<').replace(/&gt;/g,'>')
      this.loading= false

      document.title += this.contents.name
      
    })
    .catch((err) => {
      console.log(err);
      this.loading = false
      this.error = true
    })
  },
  methods : {
    showlink () {
      window.location.href = this.contents.link; // 通常の遷移
      window.open(this.contents.link, '_blank'); // 新しいタブを開き、ページを表示
    },
    externalLink() {
      window.location.href = this.contents.homepage; // 通常の遷移
      window.open(this.contents.homepage, '_blank'); // 新しいタブを開き、ページを表示
    }
  }
}
</script>