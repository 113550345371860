<template>
 <div class="row flex-column align-items-center mt-3 mt-lg-0 p-4" style="background-color: #B9D7DD;">
  <div class="col-6 col-lg-3">
    <img src="@/assets/img/home/sub_title.svg"/>
  </div>
  <div class="col-11 col-lg-7 border border-dark border-2 align-self-center my-3" style="background-color:#f0f8ff;">
    【お問い合わせ】
    <div class="d-md-flex">
      <div class="col-12 col-md-6 text-center mr-2">
        <small>ワンフォーワンアンドコー</small><br/>
        <strong>株式会社 141&Co.(委託)</strong><br/>
        <small>担当:石井</small>
      </div>
      <div class="col-12 col-md-6">
        <i class="bi bi-telephone-fill"></i> 018-853-6910<br/>
        <i class="bi bi-envelope"></i> info@one-for-one.co.jp
      </div>
    </div>
  </div>
  <div class="text-center">
    <small>秋田市新卒者地元就職促進事業</small><br/> 
    <small>主催｜秋田市産業振興部企業立地雇用課</small>
  </div>
</div>
</template>
<style>
</style>