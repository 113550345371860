import { createRouter, createWebHistory } from 'vue-router'
//import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HokenView from '../views/HokenView.vue'
import CompanyView from '../views/CompanyView.vue'
import CompanyDetailView from '../views/CompanyDetailView.vue'
import EventView from '../views/EventView.vue'
import NotFoundError from '../views/NotFoundError.vue'

//開発専用コンポーネント
import CompanyDetailViewDevelopment from '../views/CompanyDetailViewDevelopment.vue'



const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '秋田市内企業研究会サイト Bucchake!?|ホーム',
      metaTags: [
        {
          name: 'description',
          content: '秋田市内企業研究会サイトBucchake!?です。Bucchake!?は、秋田の企業のぶっちゃけた裏側の話をテーマに、就活生への企業選びのサポートになる情報・コンテンツをまとめています。'
        },
      ]
    }
  },
  {
    path: '/hoken',
    name: 'hoken',
    component: HokenView,
    meta: {
      title: '秋田市内企業研究会サイト Bucchake!?|就活の保健室'  
    }
  },

  //これは開発画面
  {
    path: '/company_development/:id',
    name: 'company_detail_development',
    component: CompanyDetailViewDevelopment,
    meta: {
      title: '開発専用画面'  
    }
  },
  //ここまで
  {
    path: '/company',
    name: 'company',
    component: CompanyView,
    meta: {
      title: '秋田市内企業研究会サイト Bucchake!?|企業一覧'  
    }
  },
  {
    path: '/company/:id',
    name: 'company_detail',
    component: CompanyDetailView,
    meta: {
      title: '秋田市内企業研究会サイト Bucchake!?|'  
    }
  },
  {
    path: '/event',
    name: 'event',
    component: EventView,
    meta: {
      title: '秋田市内企業研究会サイト Bucchake!?|イベント詳細'  
    }
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFoundError',
    component: NotFoundError,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top:0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
