import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";

const app = createApp(App)

//Bootstrap import
//import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle"
import "bootstrap-icons/font/bootstrap-icons.css"
import "@/assets/scss/custom.scss"

//microCMS
import { microCMS } from '@/plugins/microcms'

/*
app.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_ID,
    params: {
      send_page_view: false
    }
  }
});
*/
app.use(VueGtag, {
  config: {id: process.env.VUE_APP_GA_ID}
}, router);


app.use(router)
app.use(microCMS)
app.mount('#app')
