<template>

<div class="row justify-content-center py-5 border-bottom border-dark border-3 pb-2 d-none d-lg-flex sticky-top fw-bold text-center" style="background-color:#f4fafa;">
  <div class="col"><a href="#it">情報通信業</a></div>
  <div class="col"><a href="#industory">製造業</a></div>
  <div class="col"><a href="#asset">金融・不動産業</a></div>
  <div class="col"><a href="#retail">卸・小売業</a></div>
  <div class="col"><a href="#constraction">建設業</a></div>
  <div class="col"><a href="#service">サービス業・その他</a></div>
</div>

<div style="height:50px;" id="top"></div>

<div class="d-flex align-items-center justify-content-center my-4">
  <div class="col-6 col-lg-4">
    <img src="@/assets/img/home/kenkyuu_logo.svg"/>
  </div>
</div>

<div class="row justify-content-center my-4 d-flex d-lg-none">
  <div class="row d-flex m-3">
    <div class="col-4 text-center border-end"><a href="#it">情報通信業</a></div>
    <div class="col-4 text-center border-end"><a href="#industory">製造業</a></div>
    <div class="col-4 text-center"><a href="#asset">金融・不動産業</a></div>
  </div>
  <div class="row d-flex">
    <div class="col-4 text-center align-middle border-end"><a href="#retail">卸・小売業</a></div>
    <div class="col-4 text-center border-end"><a href="#constraction">建設業</a></div>
    <div class="col-4 text-center"><a href="#service">サービス業・その他</a></div> 
  </div>
</div>

<div v-show="loading" class="text-center">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<div v-show="!loading">

<!--情報通信業-->
<div class="row" id="it">
  <div class="col-6 col-lg-4 text-center mx-auto my-4">
    <div class="border-bottom border-dark fw-bold fs-5">
      <div>情報通信業</div>
    </div>
  </div>
</div>
<div class="row d-flex m-3">

  <!--Card-->
  <div class="col-12 col-md-6 col-lg-4 p-1" v-for="item in it" :key="item">
  <router-link :to="{ path: `/company/${item.id}`}">
    <div class="flex-column border border-dark rounded" style="background-color:#f2f2f2;">
      <div class="p-3 text-center">
        <img :src="item.thumbnail.url" class="img-thumbnail w-100"/>
      </div>
      <div class="text-center fs-7 pb-3">
        <strong>{{item.name}}</strong><br>
        {{item.title}}<br/>
      </div>
    </div>
  </router-link>
  </div> 
</div>

<!-- //情報通信業-->

<!--製造業-->
<div class="row" id="industory">
  <div class="col-6 col-lg-4 text-center mx-auto my-4">
    <div class="border-bottom border-dark fw-bold fs-5">
      <div>製造業</div>
    </div>
  </div>
</div>
<div class="row d-flex m-3">

  <!--Card-->
  <div class="col-12 col-md-6 col-lg-4 p-1" v-for="item in industory" :key="item">
  <router-link :to="{ path: `/company/${item.id}`}">
    <div class="flex-column border border-dark rounded" style="background-color:#f2f2f2;">
      <div class="p-3 text-center">
        <img :src="item.thumbnail.url" class="img-thumbnail w-100"/>
      </div>
      <div class="text-center fs-7 pb-3">
        <strong>{{item.name}}</strong><br>
        {{item.title}}<br/>
      </div>
    </div>
  </router-link>
  </div> 
</div>

<!-- //製造業-->

<!--金融・不動産業-->
<div class="row" id="asset">
  <div class="col-6 col-lg-4 text-center mx-auto my-4">
    <div class="border-bottom border-dark fw-bold fs-5">
      <div>金融・不動産業</div>
    </div>
  </div>
</div>
<div class="row d-flex m-3">

  <!--Card-->
  <div class="col-12 col-md-6 col-lg-4 p-1" v-for="item in asset" :key="item">
  <router-link :to="{ path: `/company/${item.id}`}">
    <div class="flex-column border border-dark rounded" style="background-color:#f2f2f2;">
      <div class="p-3 text-center">
        <img :src="item.thumbnail.url" class="img-thumbnail" w-100/>
      </div>
      <div class="text-center fs-6 pb-3">
        <strong>{{item.name}}</strong><br>
        {{item.title}}<br/>
      </div>
    </div>
  </router-link>
  </div>  
</div>

<!-- //金融・不動産業-->

<!--卸・小売業-->
<div class="row" id="retail">
  <div class="col-6 col-lg-4 text-center mx-auto my-4">
    <div class="border-bottom border-dark fw-bold fs-5">
      <div>卸・小売業</div>
    </div>
  </div>
</div>
<div class="row d-flex m-3">

  <!--Card-->
  <div class="col-12 col-md-6 col-lg-4 p-1" v-for="item in retail" :key="item">
  <router-link :to="{ path: `/company/${item.id}`}">
    <div class="flex-column border border-dark rounded" style="background-color:#f2f2f2;">
      <div class="p-3 text-center">
        <img :src="item.thumbnail.url" class="img-thumbnail" w-100/>
      </div>
      <div class="text-center fs-6 pb-3">
        <strong>{{item.name}}</strong><br>
        {{item.title}}<br/>
      </div>
    </div>
  </router-link>
  </div> 
</div>

<!-- //卸・小売業-->

<!--建設業-->
<div class="row" id="constraction">
  <div class="col-6 col-lg-4 text-center mx-auto my-4">
    <div class="border-bottom border-dark fw-bold fs-5">
      <div>建設業</div>
    </div>
  </div>
</div>
<div class="row d-flex m-3">

  <!--Card-->
  <div class="col-12 col-md-6 col-lg-4 p-1" v-for="item in construction" :key="item">
  <router-link :to="{ path: `/company/${item.id}`}">
    <div class="flex-column border border-dark rounded" style="background-color:#f2f2f2;">
      <div class="p-3 text-center">
        <img :src="item.thumbnail.url" class="img-thumbnail" w-100/>
      </div>
      <div class="text-center fs-6 pb-3">
        <strong>{{item.name}}</strong><br>
        {{item.title}}<br/>
      </div>
    </div>
  </router-link>
  </div>  
</div>

<!-- //建設業-->

<!--サービス業・その他-->
<div class="row" id="service">
  <div class="col-6 col-lg-4 text-center mx-auto my-4">
    <div class="border-bottom border-dark fw-bold fs-5">
      <div>サービス業・その他</div>
    </div>
  </div>
</div>
<div class="row d-flex m-3">

  <!--Card-->
  <div class="col-12 col-md-6 col-lg-4 p-1" v-for="item in service" :key="item">
  <router-link :to="{ path: `/company/${item.id}`}">
    <div class="flex-column border border-dark rounded" style="background-color:#f2f2f2;">
      <div class="p-3 text-center">
        <img :src="item.thumbnail.url" class="img-thumbnail" w-100/>
      </div>
      <div class="text-center fs-6 pb-3">
        <strong>{{item.name}}</strong><br>
        {{item.title}}<br/>
      </div>
    </div>
  </router-link>
  </div> 
</div>

<div class="sticky-bottom text-end" style="z-index:100">
  <a href="#top">
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" fill="currentColor" class="bi bi-arrow-up-circle" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z"/>
    </svg>
  </a>
</div>

<!-- //サービス業・その他-->
</div>
</template>

<style scoped>
a{
  color: #1D4245;
  text-decoration: none;
}

.img-thumbnail {
  display: block;
  width: 100%;/*任意の横幅を指定*/
  height: 150px;/*任意の高さを指定*/
  object-fit: contain;
  background-color: #f2f2f2;;
}
</style>
<script>
import $ from "jquery";

export default {
  name: 'CompanyView',
  data(){
    return{
      ary : [],
      it : [],
      industory : [],
      asset : [],
      retail : [],
      construction : [],
      service : [],
      loading: true,
    }
  },
  mounted : async function(){
    await this.$client.get({
      endpoint: 'company',
      queries: { limit: 100 },
    })
    .then((res) => {
      this.ary = res.contents;

      for(let item=0;item<this.ary.length;item++){
        switch(this.ary[item].type[0]){
          case '情報通信業':
            this.it.push(this.ary[item]);
            break;
          case '製造業':
            this.industory.push(this.ary[item]);
            break;
          case '金融・不動産業':
            this.asset.push(this.ary[item]);
            break;
          case '卸・小売業':
            this.retail.push(this.ary[item]);
            break;
          case '建設業':
            this.construction.push(this.ary[item]);
            break;
          case 'サービス業・その他':
            this.service.push(this.ary[item]);
            break;
        }
      }
      this.loading = false;
    })
    .catch((err) => console.log(err));

    $(function() {
      var headerHeight = 100;//固定ヘッダーの高さを入れる
      $('[href^="#"]').click(function(){
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top-headerHeight; 
        $("html, body").animate({scrollTop:position}, 200, "swing");//200はスクロールの移動スピードです
        return false;
      });
    });
  }
}

</script>